import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '@mui/joy';

export const Admin = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <SpimedImg src="images/spimed_logo_b_w.png" />
            <Typography>Oops... Wrong page</Typography>
            <Button onClick={() => navigate('/#/')}>Click here to come back !</Button>
        </Container>
    );
};

export default Admin;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 75vw;
    height: 90vh;
    background: ${(props) => props.theme.colors.lightgrey};
    border-radius: 5px;
`;

const SpimedImg = styled.img`
    width: 10vw;
`;

