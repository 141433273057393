import { getSpacing } from "./Spacing";
import colors from "./colors";

const spacing = { unit: "px", size: 4 };

type Theme = typeof theme;

export interface ThemeProps {
  theme: Theme;
}

export const theme = {
  colors,
  getSpacing: getSpacing(spacing),
};

export default theme;
