import React from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, ...rest }: any) => {
    const [sessionContext] = useSessionContext();

    return sessionContext.isAuthenticatedCE ? <Outlet /> : <Navigate to="/#" />;
};

export default ProtectedRoute;
