import { createContext } from 'react';
import React from 'react';
import { useContextFactory } from './ContextFactory';

type Props = {
    children: React.ReactNode;
};

export interface Session {
    isAuthenticatedCE?: boolean;
    accessToken: string;
}

const initialSession: Session = {
    isAuthenticatedCE: false,
    accessToken: ''
};

export const SessionContext = createContext<[Session, (accessToken: string) => void, () => void]>([initialSession, () => {}, () => {}]);

export const useSessionContext = useContextFactory('SessionContext', SessionContext);

export const SessionContextProvider: React.FC<Props> = ({ children }) => {
    const initSession = () => {
        const session = localStorage.getItem('sessionCE');
        if (session !== null) {
            return JSON.parse(session);
        }
        return initialSession;
    };

    const [sessionInStorage, setSessionInStorage] = React.useState<Session>(initSession);

    const login = (accessToken: string) => {
        const session: Session = { isAuthenticatedCE: true, accessToken };
        localStorage.setItem('sessionCE', JSON.stringify(session));
        setSessionInStorage(session);
    };

    const logout = () => {
        localStorage.removeItem('sessionCE');
        setSessionInStorage(initialSession);
    };

    const defaultSessionContext: [Session, typeof login, typeof logout] = [sessionInStorage, login, logout];
    return <SessionContext.Provider value={defaultSessionContext}>{children}</SessionContext.Provider>;
};
