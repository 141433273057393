import { createTheme } from "@mui/material";
import colors from "./colors";

export const MuiOverrideTheme = createTheme({
  typography: {
    h1: {
      fontSize: "2.85rem" /* 32px - 1 pigment rem = 13px */,
      fontStyle: "normal",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.25rem" /* 18px - 1 pigment rem = 13px */,
      fontStyle: "normal",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.10rem" /* 15px - 1 pigment rem = 13px */,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.7",
    },
    h4: {
      fontSize: "0.8rem" /* 15px - 1 pigment rem = 13px */,
      fontStyle: "normal",
      fontWeight: 100,
      lineHeight: "1.7",
    },
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    htmlFontSize: 13,
    fontSize: 13, // By default Material UI uses this size for Button, Tabs...
    button: {
      textTransform: "none",
      fontSize: "1.10rem",
    },
  },
  palette: {
    primary: {
      main: colors.blue,
    },
  },
});

export default MuiOverrideTheme;
