import * as React from 'react';
import styled from 'styled-components';
import { useSessionContext } from '@/utils/contexts/SessionContext';

import LogoutButton from '../molecules/LogoutButton';

export const Footer = () => {
    const [sessionContext] = useSessionContext();

    return (
        <SContainer>
            {sessionContext.isAuthenticatedCE ? (
                <>
                    <SBottomLeftContainer>
                        <SImg src="images/logo_darkblue_spimed.png" />
                    </SBottomLeftContainer>
                    <SBottomRightContainer>
                        <LogoutButton />
                    </SBottomRightContainer>
                </>
            ) : (
                <></>
            )}
        </SContainer>
    );
};
export default Footer;

const SContainer = styled.div`
    position: relative;
    width: 100%;
    height: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SBottomRightContainer = styled.div`
    && {
        bottom: 2vh;
        position: fixed;
        right: 0;
        margin-right: ${(props) => props.theme.getSpacing(8)};
    }
`;

const SBottomLeftContainer = styled.div`
    && {
        bottom: 1vh;
        position: fixed;
        left: 0;
        margin-left: ${(props) => props.theme.getSpacing(4)};
    }
`;

const SImg = styled.img`
    height: 10vh;
`;
