import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import App from './App';

// Utils
import theme from '@/utils/styles/Theme';
import { MuiOverrideTheme } from '@/utils/styles/MuiOverrideTheme';
import { SessionContextProvider } from '@/utils/contexts/SessionContext';

import { CircularProgress } from '@mui/joy';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    color: hsla(0, 0%, 0%, 0.8);
  }
`;

ReactDOM.render(
    <ThemeProvider theme={MuiOverrideTheme}>
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <SessionContextProvider>
                <Suspense fallback={<CircularProgress />}>
                    <App />
                </Suspense>
            </SessionContextProvider>
        </ThemeProvider>
    </ThemeProvider>,
    document.getElementById('root')
);

