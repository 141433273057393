import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useSessionContext } from '@/utils/contexts/SessionContext';
import { Button } from '@mui/joy';

import LogoutIcon from '@mui/icons-material/Logout';

export const LogoutButton = () => {
    const [, , logout] = useSessionContext();
    const navigate = useNavigate();

    const handleClick = () => {
        logout();
        navigate('/');
    };

    return (
        <Button onClick={handleClick} color="danger" variant="solid" startDecorator={<LogoutIcon />}>
            Log out
        </Button>
    );
};

export default LogoutButton;
