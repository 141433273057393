import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { Typography } from '@mui/joy';
import { CircularProgress } from '@mui/joy';

// Joy UI
import Button from '@mui/joy/Button';

// Material UI
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { InputAdornment } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ErrorIcon from '@mui/icons-material/Error';

// Utils
import { useSessionContext } from '@/utils/contexts/SessionContext';
import { Alert } from '@mui/joy';

type VisibilityProps = {
    visibility: string;
};

declare const VERSION: string;

export const Login = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [wrongIdentification, setWrongIdentification] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [showKey, setShowKey] = useState<boolean>(false);

    // Session context
    const [, login] = useSessionContext();

    // Navigate
    const navigate = useNavigate();

    const onLogin = async () => {
        try {
            setLoading(true);
            setError(false);
            setWrongIdentification(false);
            const url = process.env.pythonURL + `/connect`;
            let form = new FormData();
            form.append('Password', password.trim());
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Access-Control-Allow-Origin': '*' },
                body: form
            }).then((response) => response.json());
            if (response.data === false) {
                setWrongIdentification(true);
                setLoading(false);
                return -1;
            }
            setLoading(true);
            login(response.data.access);
            navigate('/upload');
            return 1;
        } catch (err) {
            setError(true);
            setLoading(false);
            return -1;
        }
    };

    return (
        <>
            <SBackground>
                <SpimedImg src="images/logo_darkblue_spimed.png" />
                <Typography level="h1" sx={{ color: '#FFFFFF' }}>
                    CorEx DICOM Upload
                </Typography>
                <Typography level="h3" sx={{ color: '#FFFFFF', marginBottom: '10px' }}>
                    Access key
                </Typography>
                <STextField
                    id="access-key"
                    type={showKey ? 'text' : 'password'}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                    inputProps={{ style: { textAlign: 'center' } }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SKeyIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowKey(!showKey)} onMouseDown={() => setShowKey(!showKey)}>
                                    {showKey ? <SVisibility /> : <SVisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <SBottomContainer>
                    <SDiv visibility={password === '' ? 'hidden' : 'visible'}>
                        <Button variant="soft" disabled={password === '' || loading} onClick={() => onLogin()}>
                            {!loading ? <Typography>Login</Typography> : <CircularProgress />}
                        </Button>
                    </SDiv>
                    <SWrongKey>
                        {error && (
                            <Alert color="danger" variant="soft">
                                <Typography sx={{ color: '#C23838' }} startDecorator={<ErrorIcon />}>
                                    Issue with server
                                </Typography>
                            </Alert>
                        )}
                        {wrongIdentification && (
                            <Alert color="danger" variant="soft">
                                <Typography sx={{ color: '#C23838' }} startDecorator={<ErrorIcon />}>
                                    Wrong access key
                                </Typography>
                            </Alert>
                        )}
                    </SWrongKey>
                </SBottomContainer>
            </SBackground>
            <SFooter>
                <SFooterImg src="images/spimed_logo.png" />
                <Typography sx={{ color: '#FFFFFF', marginLeft: '10px' }}>Built by Spimed-AI - 2023 - (Version : {VERSION})</Typography>
            </SFooter>
        </>
    );
};

export default Login;

const SBackground = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 96vh;
    width: 100vw;
    background-color: ${(props) => props.theme.colors.darkblue};
    user-select: none;
`;

const SFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 4vh;
    width: 100vw;
    background-color: ${(props) => props.theme.colors.pinkred};
    user-select: none;
`;

const SFooterImg = styled.img`
    width: 3vh;
`;

const SDiv = styled.div<VisibilityProps>`
    display: flex;
    visibility: ${(props: VisibilityProps) => props.visibility};
    justify-content: center;
    align-items: center;
`;

const STextField = styled(TextField)`
    color: white;
    width: 25vw;
    border-radius: 10px;
    margin: ${(props) => props.theme.getSpacing(4, 0, 4, 0)};
    background: linear-gradient(145deg, #0c386d, #0a2f5c);
    box-shadow: 5px 5px 10px #0a2f5d, -5px -5px 10px #0c396f;
    & .MuiInput-root {
        color: white;
    }
    & label {
        color: rgba(255, 255, 255, 0.7);
    }
    & .MuiTextField-root {
        color: white;
    }
    & label.Mui-focused {
        color: white;
    }
    & .MuiInput-underline {
        border-bottom-color: rgba(255, 255, 255, 0.7);
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiOutlinedInput-root {
        & input {
            color: white;
            &:-webkit-autofill {
                -webkit-text-fill-color: white;
                caret-color: white;
                box-shadow: 0 0 0 50px #0b3160 inset !important;
                border-radius: 10px 0 0 10px;
            }
            &:-webkit-autofill:hover {
                box-shadow: 0 0 0 50px #0b3160 inset !important;
            }
            &:-webkit-autofill:focus {
                box-shadow: 0 0 0 50px #0b3160 inset !important;
            }
        }
        & fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
        &:hover fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
        &.Mui-focused fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
    }
`;

const SpimedImg = styled.img`
    width: 7vw;
`;

const SKeyIcon = styled(KeyIcon)`
    color: #ffffff;
    padding: ${(props) => props.theme.getSpacing(3)};
`;

const SVisibility = styled(Visibility)`
    color: rgba(255, 255, 255, 0.9);
    &:hover {
        color: rgba(255, 255, 255, 1);
    }
`;

const SVisibilityOff = styled(VisibilityOff)`
    color: rgba(255, 255, 255, 0.7);
    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }
`;

const SBottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-top: ${(props) => props.theme.getSpacing(5)};
    padding-bottom: ${(props) => props.theme.getSpacing(5)};
`;

const SWrongKey = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
`;
