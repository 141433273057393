import { FormControl } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from '../utils/contexts/SessionContext';
import JSZip from 'jszip';

// Joy UI
import Input from '@mui/joy/Input';
import { Typography } from '@mui/joy';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import FormHelperText from '@mui/joy/FormHelperText';

// Components
import Button from '@mui/joy/Button';
import ListDivider from '@mui/joy/ListDivider';
import Checkbox from '@mui/joy/Checkbox';

// Icons
import EmailIcon from '@mui/icons-material/Email';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';

import { Chip } from '@mui/joy';

export const Upload = () => {
    const [name, setName] = useState<string>('');
    const [aeTitle, setAeTitle] = useState<string>('externalAE');
    const [hospitalName, setHospitalName] = useState<string>('external');
    const [zipFile, setZipFile] = useState<File>();

    const [loading, setLoading] = useState<boolean>(false);

    const [success, setSuccess] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [tokenExpired, setTokenExpired] = useState<boolean>(false);

    const [error, setError] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState<string>('');

    // Navigate
    const navigate = useNavigate();

    // Role
    const [sessionContext, , logout] = useSessionContext();
    const accessToken = sessionContext.accessToken;

    // Usestate folder
    const [folder, setFolder] = useState<FileList | null>(null);
    const [folderName, setFolderName] = useState<string>('');
    const [displayErrorOnFolderUpload, setDisplayErrorOnFolderUpload] = useState<string>('');
    const [displayErrorMessage, setDisplayErrorMessage] = useState<boolean>(false);
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState<boolean>(false);

    // Function to set all informations into ZIP file
    const handleFolderSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setDisplayErrorMessage(false);
        setDisplaySuccessMessage(false);
        if (files?.length === 0) {
            setFolderName('');
            setFolder(null);
            setDisplayErrorOnFolderUpload(
                'Le dossier sélectionné est vide. Veuillez sélectionner un dossier contenant des fichiers DICOM.'
            );
        } else {
            setDisplayErrorOnFolderUpload('');
            var file = event.target.files?.[0];
            if (file) {
                var name = file.webkitRelativePath.split('/')[0] || 'Folder';
                setFolderName(name);
            }
            setFolder(files);
            setChecked(false);
        }
    };

    // Function to upload the Folder
    const handleFolderUpload = async () => {
        if (!folder) {
            return;
        }

        setLoading(true);

        const zip = new JSZip();

        var dicomExtensions = ['.dcm', '.dicom', '.dic', ''];

        for (let i = 0; i < folder.length; i++) {
            const file = folder[i];
            const path = file.webkitRelativePath;

            if (file.type === 'application/x-directory') {
                continue;
            }

            const fileContent = await file.arrayBuffer();
            var extension: string = path.match(/\.[^.]+$/)?.[0] ?? '';
            if (dicomExtensions.includes(extension)) {
                var fileName = 'file' + i + extension;
                zip.file(fileName, fileContent);
            }
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        try {
            // Reset verification
            setError(false);
            setSuccess(false);
            setTokenExpired(false);

            const url = process.env.pythonURL + `/upload`;
            let form = new FormData();

            form.append('Name', name);
            form.append('AeTitle', aeTitle);
            form.append('Hospital', hospitalName);
            form.append('ZipFile', zipBlob, 'upload.zip');

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${accessToken}`
                    // 'Content-Type': 'multipart/form-data'
                },
                body: form
            }).then((response) => response.json());
            if (response.msg === 'Token has expired') {
                setTokenExpired(true);
                setLoading(false);
                setTimeout(() => {
                    logout();
                }, 3000);
                return -1;
            } else if (response.data.status !== 201) {
                setError(true);
                setResponseMessage(response.data.msg);
                setLoading(false);
                return -1;
            }
            setLoading(false);
            setResponseMessage(response.data.msg);
            setSuccess(true);

            setChecked(false);

            // Folder usestate
            setDisplayErrorOnFolderUpload('');
            return 1;
        } catch (err: any) {
            setError(true);
            setResponseMessage(err);
            setLoading(false);
            setChecked(false);
            return -1;
        }
    };

    return (
        <SFullContainer>
            <Container>
                <Typography level="h1">Upload DICOM Folder</Typography>
                <SColumn>
                    <List sx={{ '--ListItemDecorator-size': '32px', width: '100%' }}>
                        <ListItem>
                            <FormControl sx={{ width: '100%' }}>
                                <Input
                                    color="neutral"
                                    placeholder="Name of study"
                                    variant="outlined"
                                    startDecorator={<LocalOfferRoundedIcon />}
                                    onChange={(event) => setName(event.target.value)}
                                />
                                <FormHelperText>Mandatory</FormHelperText>
                            </FormControl>
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'space-between' }}>
                            <FormControl sx={{ width: '50%' }}>
                                <Input
                                    color="neutral"
                                    placeholder="AE-Title"
                                    variant="outlined"
                                    startDecorator={<ConfirmationNumberIcon />}
                                    onChange={(event) => setAeTitle(event.target.value)}
                                />
                                <FormHelperText>Optional</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ width: '50%', marginLeft: '10px' }}>
                                <Input
                                    color="neutral"
                                    placeholder="Hospital Name"
                                    variant="outlined"
                                    startDecorator={<LocalHospitalIcon />}
                                    onChange={(event) => setHospitalName(event.target.value)}
                                />
                                <FormHelperText>Optional</FormHelperText>
                            </FormControl>
                        </ListItem>
                        <ListDivider />
                        <ListItem sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Button component="label" startDecorator={<DriveFolderUploadIcon />}>
                                Add a folder
                                <input
                                    id="zip_upload"
                                    type="file"
                                    hidden
                                    onChange={(e) => handleFolderSelect(e)}
                                    multiple
                                    /* @ts-expect-error */
                                    directory=""
                                    webkitdirectory=""
                                />
                            </Button>
                        </ListItem>
                        <ListItem sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            {folder && (
                                <Typography endDecorator={<Chip size="sm">{folder?.length} fichiers</Chip>} justifyContent="center">
                                    {folderName}
                                </Typography>
                            )}
                        </ListItem>
                        <ListDivider />
                        <ListItem sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <FormControl sx={{ alignItems: 'center' }}>
                                <Checkbox
                                    label={
                                        <React.Fragment>
                                            I certify that the uploaded DICOM files have been anonymized before selection
                                        </React.Fragment>
                                    }
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                />
                            </FormControl>
                        </ListItem>
                        <ListDivider />
                        <ListItem>
                            <ListItemDecorator>{name === '' ? '❌' : '✅'}</ListItemDecorator>Please add a valid name
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>{folder?.length !== 0 && folder?.length !== undefined ? '✅' : '❌'}</ListItemDecorator>
                            <Typography>Please add a folder containing anonymized DICOM files</Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>{!checked ? '❌' : '✅'}</ListItemDecorator>Please certify that the DICOM files have been
                            previously anonymized
                        </ListItem>
                        <ListItem sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                startDecorator={<FileUploadIcon />}
                                disabled={name === '' || zipFile === null || !checked}
                                onClick={() => handleFolderUpload()}
                                loading={loading}
                                sx={{ width: '100%' }}
                            >
                                Upload
                            </Button>
                        </ListItem>
                        <ListItem sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            {error && (
                                <Typography variant="soft" color="danger" sx={{ textJustify: 'justify' }}>
                                    ERROR : {responseMessage.toString()}
                                </Typography>
                            )}
                            {success && (
                                <Typography variant="soft" color="success" sx={{ textJustify: 'justify' }}>
                                    SUCCESS : Upload achieved
                                </Typography>
                            )}
                            {tokenExpired && (
                                <Typography variant="soft" color="danger" sx={{ textJustify: 'justify' }}>
                                    ERROR SESSION : Session expired, you will be logged out
                                </Typography>
                            )}
                        </ListItem>
                    </List>
                </SColumn>
            </Container>
        </SFullContainer>
    );
};

export default Upload;

const SFullContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 55vw;
    height: 80vh;
    background: ${(props) => props.theme.colors.lightgrey};
    border-radius: 25px;
`;

const SColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50vw;
`;
