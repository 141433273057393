import React from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export const PublicRoute = ({ component: Component, ...rest }: any) => {
    const [sessionContext] = useSessionContext();

    return <>{sessionContext !== null ? sessionContext.isAuthenticatedCE ? <Navigate to="/upload" /> : <Outlet /> : <Outlet />}</>;
};

export default PublicRoute;
