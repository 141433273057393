export const getSpacing = (spacing: { unit: string; size: number }) => {
  function generateSpacing(multiplier: number): string;
  function generateSpacing(
    verticalMultiplier: number,
    horizontalMultiplier: number
  ): string;
  function generateSpacing(
    topMultiplier: number,
    horizontalMultiplier: number,
    bottomMultiplier: number
  ): string;
  function generateSpacing(
    topMultiplier: number,
    rightMultiplier: number,
    bottomMultiplier: number,
    leftMultiplier: number
  ): string;
  function generateSpacing(...multipliers: number[]): string {
    return multipliers
      .map(
        (multiplier) =>
          `${getSpacingSize(spacing.size)(multiplier)}${spacing.unit}`
      )
      .join(" ");
  }
  return generateSpacing;
};

const getSpacingSize =
  (unitSize: number) =>
  (multiplier: number): number =>
    multiplier * unitSize;

export default getSpacing;
