export const colors = {
  white: "#FFFFFF",
  black: "#020202",
  lightgrey: "#E1E4E8",
  grey: "#C2C2C2",
  darkgrey: "#757575",
  strongdarkgrey: "#474747",
  blue: "#55B9F3",
  skyblue: "#D6F0FF",
  green: "#53DFA2",
  orange: "#FCB97D",
  yellow: "#FFD166",
  red: "#FF4747",
  lightyellow: "#FFEFD6",
  pinkred: "#F64A77",
  darkred: "#C23838",
  gold: "#FFD700",
  darkblue: "#052F62",
  electricblue: "#3C6BFC",
  lightelectricblue: "#9AB1FE",
  utility: {
    backdrop: "rgba(5, 11, 32, 0.5)",
  },
};

export default colors;
