import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

// Utils
import ProtectedRoute from '@/utils/routes/ProtectedRoute';
import PublicRoute from '@/utils/routes/PublicRoute';

// Pages
import Login from './Login';
import Upload from './Upload';
import NotFound from './NotFound';

import Footer from '@/components/organisms/Footer';

export const App = () => {
    return (
        <HashRouter>
            <SContainer>
                <Routes>
                    <Route path="/" element={<PublicRoute />}>
                        <Route path="/" element={<Login />} />
                    </Route>
                    <Route path="/upload" element={<ProtectedRoute />}>
                        <Route path="/upload" element={<Upload />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </SContainer>
            <Footer />
        </HashRouter>
    );
};

export default App;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    /* background-color: #175485; */
`;

